import { configureFormTheme, FormThemeContext } from 'frr-web/lib/theme/theme.form'
import { ComponentThemeContext, configureComponentTheme } from 'frr-web/lib/theme/theme.components'
import React, { ReactNode } from 'react'
import { componentTheme } from 'app/style/theme.component'
import { formTheme } from 'app/style/theme.form'
import { createGlobalStyle } from 'styled-components'

export const switchThemeMode = () => {
  var element = document.documentElement
  if (element) element.classList.toggle('dark-mode')
}

export const configureTheme = () => {
  const isDarkMode = window.localStorage.getItem('darkMode') === 'enabled'
  var element = document.documentElement
  if (element && isDarkMode) element.classList.add('dark-mode')
}

export const configureBaseStyle = () => createGlobalStyle`
  h1, h2, h3, h4, h5, h6, li, p, a, span {
    margin: 0;
  } 
`

export const ThemeProvider = (props: { children: ReactNode }) => {
  const BaseStyle = configureBaseStyle()

  configureTheme()

  return (
    <ComponentThemeContext.Provider value={configureComponentTheme(componentTheme)}>
      <BaseStyle />
      <FormThemeContext.Provider value={configureFormTheme(formTheme)}>
        {props.children}
      </FormThemeContext.Provider>
    </ComponentThemeContext.Provider>
  )
}
