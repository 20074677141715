import { useAppConfig } from 'app/data/app.data'
import { useUser, useUserPermissions, useUserViewContext } from 'app/data/user.data'
import { AppConfig } from 'app/types/app.types'
import { UserDTO, UserPermission, UserPermissions, UserViewContext } from 'app/types/user.types'
import { ReactNode, useEffect } from 'react'

const prepareUserPermissions = ({ appConfig, user }: { appConfig: AppConfig; user: UserDTO }) => {
  return user.permissions.map(
    (permission) => appConfig.azure.userPermissionGroups[permission] || UserPermission.Unknown,
  )
}

const mapUserPermissions = ({
  appConfig,
  user,
}: {
  appConfig: AppConfig
  user: UserDTO
}): UserPermissions => {
  const permissions = prepareUserPermissions({ appConfig, user })
  return {
    hasCustomerImpersonationPermission: permissions.includes(UserPermission.CustomerImpersonation),
    hasCustomerUpdatePermission: permissions.includes(UserPermission.CustomerUpdate),
    hasCustomerViewHiddenPermission: permissions.includes(UserPermission.CustomerViewHidden),
    hasDeveloperPermission: permissions.includes(UserPermission.Developer),
    hasFinancingCancelContractPermission: permissions.includes(UserPermission.FinancingCancelContract),
    hasFinancingCreditScoringPermission:
      permissions.includes(UserPermission.FinancingCreditScoring) ||
      permissions.includes(UserPermission.Developer),
    hasFinancingViewPermission: permissions.includes(UserPermission.FinancingView),
    hasFinancingViewHiddenPermission: permissions.includes(UserPermission.FinancingViewHidden),
    hasFinancingViewDtoPermission: permissions.includes(UserPermission.FinancingViewDto),
    hasFinancingWithdrawApplicationPermission: permissions.includes(
      UserPermission.FinancingWithdrawApplication,
    ),
    hasPartnerCreatePermission: permissions.includes(UserPermission.PartnerCreate),
    hasPartnerListPermission: permissions.includes(UserPermission.PartnerView),
    hasPartnerUpdatePermission: permissions.includes(UserPermission.PartnerUpdate),
    hasSupportPermission: permissions.includes(UserPermission.Support),
  }
}

export const UserContextAndPermissionsProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()
  const { user } = useUser()

  const { userViewContext, setUserViewContext } = useUserViewContext()
  const { setUserPermissions } = useUserPermissions()

  useEffect(() => {
    if (userViewContext === UserViewContext.Loading) {
      setUserViewContext(
        user.isBobInternal ? UserViewContext.InternalDashboard : UserViewContext.PartnerPortal,
      )
    }

    setUserPermissions(mapUserPermissions({ appConfig, user }))
  }, [])

  return <>{userViewContext === UserViewContext.Loading ? null : props.children}</>
}
